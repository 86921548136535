import React from "react";
import PageWrapper from "../components/PageWrapper";
import imgB1 from "../assets/image/AKB.jpeg";
import imgB2 from "../assets/image/rajesh.jpg";
import "./custom-header-bg.css"

const About = () => {
    return (
        <>
            <PageWrapper
                headerConfig={{
                    bgClass: "dynamic-sticky-bg",
                }}>
                <section id="careers" className="pt-24 pt-lg-32 pb-7 pb-lg-16 custom-header-background ">
                    <div className="container">
                        {/* <!-- Section Title --> */}
                        <div className="row justify-content-center mb-lg-8 mb-8">
                            <div className="col-xxl-6 col-xl-7 col-lg-8 col-md-10 text-center">
                                <h2 className="mb-4 mb-lg-4 text-white font-size-10">
                                    About us
                                </h2>
                            </div>
                        </div>

                        <div className="row justify-content-center mb-lg-8 mb-8">
                            <div className="col-xxl-10 col-xl-10 col-lg-10 col-md-10">
                                <h2 className="mb-4 mb-lg-4 text-white font-size-6 text-center">
                                    Purpose
                                </h2>
                                <p className="text-white">At E-CTO, our purpose is providing technology prowess for Corporations and Entrepreneurs.  Our network of outstanding technology experts, Global research labs, empaneled software houses enable our customers to accelerate ideas to markets. We provide challenging opportunities and rewarding projects for our Research and technology development affiliates.</p>
                            </div>
                        </div>
                    </div>
                </section>

                <section>
                    <div className="container mt-lg-16 mt-8">
                        <div className="row justify-content-center mb-lg-8 mb-8">
                            <div className="col-xxl-10 col-xl-10 col-lg-10 col-md-10">
                                <h2 className="mb-8 mb-lg-8 text-black-2 font-size-6 text-center">
                                    Leadership Team
                                </h2>
                                <p>We are hiring and expanding our leadership team.</p>
                            </div>
                        </div>

                        <div style={{ textAlign: "center" }}>
                            <img src={imgB1} alt="about" style={{ width: "120px", borderRadius: "50%" }} />
                        </div>
                        <div className="row justify-content-center mb-lg-8 mb-8">
                            <div className="col-xxl-10 col-xl-10 col-lg-10 col-md-10" style={{ marginTop: '25px' }}>
                                <div>
                                    <span>Krishna Bhagavan Akumalla is the acting CEO and Head of Business operations of E-CTO.COM. He is an experienced leader in domains of Talent Management, L&D, Change Management, Business Transformation, Strategic Business Unit Leadership in Fortune 500 companies.
                                    </span><br /><br />

                                    <span>He did his Masters in Business Management from IIM Calcutta, India.
                                    </span><br /><br />
                                </div>
                            </div>
                        </div>

                        <div style={{ textAlign: "center" }}>
                            <img src={imgB2} alt="about" style={{ width: "100px", borderRadius: "50%" }} />
                        </div>
                        <div className="row justify-content-center mb-lg-8 mb-8">
                            <div className="col-xxl-10 col-xl-10 col-lg-10 col-md-10" style={{ marginTop: '25px' }}>
                                <div>
                                    <span>Rajesh Kumar is the Chief Mentor and Head of Technology initiatives at E-CTO.COM. He brings with him more than 3 decades of experience in Deep Tech Industries.
                                    </span><br /><br />

                                    <span>A B Tech in Computer Science & Engineering from IIT Kanpur, Rajesh has had the unique opportunity to work on leading edge technologies throughout his professional journey - from Visual computing systems and software in '80s to Parallel Computing in '90s to Imaging & digital TV technologies in '00s to Computer Vision, ML, AI and Autonomous driving in '10s to Brain Inspired Computing now.
                                    </span>

                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </PageWrapper>
        </>
    );
};
export default About;
